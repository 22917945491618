import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const LoadingCircle = ({ height }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={height}
      zIndex={5}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        size="xl"
        color="teal"
      />
    </Flex>
  );
};

export default LoadingCircle;
