import { extendTheme } from "@chakra-ui/react"


const customTheme = extendTheme({
    colors: {
        ithinkGradient: "linear(to-r, teal.500, green.500)",
        homeButtons: "linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%)",
        homeBackground: "linear-gradient(to top, #30cfd0 0%, #330867 100%)"
    },
})

export default customTheme
